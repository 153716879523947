import { navigate } from 'gatsby'

export default function VideoPageRedirect({ location }) {
  if (typeof window !== 'undefined') {
    const path = location.pathname.split('/').filter(Boolean)
    path.pop()
    navigate(`/${path.join('/')}`)  
  }
  return null
}
